import React, { memo, useEffect } from 'react';
import styles from './index.module.scss'; // Import CSS module
import { disableBodyScroll } from '~/common/utils/carDetailValidator';
import { PopupModalModel } from '../models/owners-landing-model';
import { useMediaQuery } from 'react-responsive';
import { mobileMax, tabletMax } from '~/common/utils';
import { SvgIcon } from '~/common/components/ui-elements';

export interface PopupModalNativeProps {
  content: PopupModalModel[];
  isVisible: boolean;
  close: () => void;
}

const PopupModalNative = ({
  content,
  isVisible,
  close,
}: PopupModalNativeProps) => {
  const isSmall = useMediaQuery({ query: `(max-width: ${mobileMax}px)` });

  const popupContent = {
    text: `${content?.[0]?.editor1 || ''}
           <img src="/dist/assets/images/wheres-my-vin.jpg" alt="Where's my VIN" />
           ${content?.[0]?.editor2 || ''}`,
  };

  useEffect(() => {
    if (isVisible) {
      const handleKeyDown = (e: KeyboardEvent) => {
        if (e.key === 'Escape') {
          close();
        }
      };
      document.addEventListener('keydown', handleKeyDown);
      return () => {
        document.removeEventListener('keydown', handleKeyDown);
      };
    }
  }, [isVisible, close]);

  const renderContent = () => (
    <div
      className={`${styles.popupWrapper} ${isVisible ? styles.isVisible : ''}`}
    >
      <div className={styles.popupInner}>
        <div className={styles.popupContentScroll}>
          <div dangerouslySetInnerHTML={{ __html: popupContent.text }} />
        </div>
        <div className={styles.popupClose} onClick={() => close()}>
          <SvgIcon type="close" size={2} color="#646566" />
        </div>
      </div>
    </div>
  );

  return (
    <div
      className={`${styles.popupContainer} ${
        isSmall ? styles.popupContainerSmall : ''
      } ${isVisible ? styles.isVisible : ''}`}
      onClick={() => {
        close();
      }}
    >
      {content ? renderContent() : null}
    </div>
  );
};

export default memo(PopupModalNative);
