const infomediaApiUrl = '/api/infomedia/';

export function createInfomediaStore() {
  return {
    findCarByVin: async (vin: string) => {
      const url = `${infomediaApiUrl}vehicle/find-by-vin/${vin}`;
      try {
        const response = await fetch(url);
        const status = response.status;
        const data = await response.json();
        if (!response.ok) {
          throw new Error(`Error fetching recall data: ${response.statusText}`);
        }
        return { data, status };
      } catch (error) {
        console.error('Error fetching vehicle recalls:', error);
        return { data: null, status: 500 };
      }
    },
  };
}

export const submitManualData = (data: any, vehicleLine: string) => {
  if (!data?.model?.yearFrom) {
    console.error('Model year is missing.');
    return;
  }
  const hasCode = data.model.modelCode ? `-${data.model.modelCode}` : '';
  const hasNoVehicleName = vehicleLine
    ? vehicleLine
    : data.model?.description?.split('')[0];
  window.location.href = `/owners/owners-resources/manuals/?model=${data.model.yearFrom}-${hasNoVehicleName}${hasCode}`;
};
