export const validateVIN = (vin: string) => {
  // 17 length
  if (vin.length !== 17) {
    return 'Vehicle Identification Number should be 17 characters long.';
  }
  // 17 Alpha-numeric characters
  const regex1 = new RegExp('^[a-zA-Z0-9]+$');
  if (!regex1.test(vin)) {
    return 'Vehicle Identification Number contains invalid characters. Only alpha-numeric characters allowed without spaces.';
  }
  // case-insensitive letters: i, o and q should be rejected
  const regex2 = new RegExp('/[ioq]+/i');
  if (regex2.test(vin)) {
    return 'Vehicle Identification Number cannot contain characters uppercase I, O, Q and lowercase i, o and q.';
  }
  // The last 6 characters has to be numeric digits
  const regex3 = new RegExp('[0-9]{6}$');
  if (!regex3.test(vin)) {
    return 'Vehicle Identification Number must contain numeric values for the final 6 characters.';
  }
  return '';
};

export const validateRegistration = (rego) => {
  // 7 or less
  if (rego.length > 7) {
    return 'Registration is too long.';
  }
  // Alpha-numeric characters
  const regex1 = new RegExp('^[a-zA-Z0-9]+$');
  if (!regex1.test(rego)) {
    return 'Registration number contains invalid characters. Only alpha-numeric characters allowed without spaces.';
  }
  return '';
};

export const isSubaruVin = (vin) => {
  const subaruPattern = /^(JF1|JF2|4S4)(\w){14}$/i;
  const result = subaruPattern.test(vin);
  return result;
};

export const disableBodyScroll = () => {
  document.getElementsByTagName('body')[0].classList.add('popup-is-open');
};
